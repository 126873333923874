.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;

 
}

.loader-container .loading-box {
  width: 160px;
  height: 160px;
  background: #Fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}