* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-weight: 400;
  line-height: 1.65;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'DM Sans', sans-serif;
  background:  #F8F7F4;
}


body:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: linear-gradient(180deg, #F8F0E3 0%, #F8F7F4 100%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.17;
  font-family: 'DM Serif Text', serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

input,
select,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: sans-serif;
  font-size: 16px;
}

input[type="email"],
input[type="text"],
input[type="password"],
input[type="search"] {
  display: block;
  width: 100%;
}

a,
button,
label,
input[type="submit"],
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 1px solid #BCB9B4;
  transition: 0.2s border-color, 0.2s box-shadow;
  vertical-align: middle;
  margin-top: -2px;
  display: inline-block;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  opacity: 0.5;
  pointer-events: none;
}

input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
  opacity: 0.5;
  pointer-events: none;
}

input[type="radio"]:checked,
input[type="radio"]:hover,
input[type="checkbox"]:checked,
input[type="checkbox"]:hover {
  border: 1px solid #2573BA;
  box-shadow: 0px 0px 0px 4px #2573BA inset;
}


input[type="radio"] {
  border-radius: 50%;
}

img,
svg {
  display: block;
  max-width: 100%;
}

iframe {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.home-swiper {
  overflow: initial;
}

.home-swiper .swiper-pagination {
  z-index: 10;
}

.home-swiper .swiper-pagination-bullet {
  width: 40px;
  height: 5px;
  border-radius: 0;
  opacity: 0.4;
  background-color: #BCB9B4;
}

.home-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
